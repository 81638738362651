import React, {useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginForm from './pages/LoginForm';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Events from './components/Events';
import BookTours from './components/Tours';
import TourBookingForm from './components/BookingForm';
import About from './pages/About';
import Contact from './pages/Contact';
import Community from './pages/Community';
import Blog from './pages/Blog';
import Partnerships from './pages/Partnerships';
import Profile from './pages/Profile';
import EventList from './pages/EventList';
// import VendorRegistrationForm from './pages/VendorRegistration';
import VolunteerRegistrationForm from './pages/VolunteerRegistration';
import CompleteSignIn from './pages/CompleteSignin';
import AfroHall from './pages/AfroHall';
import Post from './components/Post';
import EventArchive from './pages/EventArchive';
import News  from './components/News';
import Tech from './pages/Tech';
import EventsArchive from './components/EventsArchive';
//import logo from './logo.svg';
import ReactGA from 'react-ga4';
import './App.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import AdminDashboard from './components/admin/AdminDashboard';
import Login from './components/auth/Login';
import Navigation from './components/Navigation';
import EventDetails from './components/EventDetails';

//Initialize google analytics
ReactGA.initialize('G-KP8M33W4RQ');

function App() {
  return (
    <HelmetProvider>
      <Router>
        <AnalyticsComponent />
        <div className="App">
          <Navigation />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/events" element={<Events />} />
            <Route path="/events/archive" element={<EventsArchive />} />
            <Route path="/events/:eventId" element={<EventDetails />} />
            <Route path="/news" element={<News />} />
            <Route path="/partnerships" element={<Partnerships />} />
            <Route path="/tech" element={<Tech />} />
            <Route path="/admin" element={<AdminDashboard />} />
            <Route path="/login" element={<Login />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/volunteer" element={<VolunteerRegistrationForm />} />
            <Route path="/tours" element={<BookTours />} />
            <Route path="/book-tours" element={<TourBookingForm />} />
            <Route path="/complete-signin" element={<CompleteSignIn />} />
            <Route path="/post/:slug" element={<Post />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
}

function AnalyticsComponent() {
  const location = useLocation();
  
  useEffect(() => {
    // Initialize GA4 with your measurement ID
    ReactGA.initialize([
      {
        trackingId: "G-KP8M33W4RQ",
      },
    ]);

    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: document.title });
  
  }, [location]);
  return null;
}

export default App;
