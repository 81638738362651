import { db } from '../firebase';
import { 
  collection, 
  query, 
  getDocs, 
  where, 
  orderBy, 
  limit, 
  startAfter,
  doc,
  getDoc 
} from 'firebase/firestore';

const EVENTS_PER_PAGE = 6;

export const eventService = {
  // Get events with pagination and filtering
  getEvents: async (category = 'all', lastVisible = null) => {
    try {
      let eventsQuery;
      const eventsRef = collection(db, 'events');

      if (category === 'all') {
        eventsQuery = query(
          eventsRef,
          orderBy('date', 'desc'),
          limit(EVENTS_PER_PAGE)
        );
      } else {
        eventsQuery = query(
          eventsRef,
          where('category', '==', category),
          orderBy('date', 'desc'),
          limit(EVENTS_PER_PAGE)
        );
      }

      // If we have a last visible document, start after it
      if (lastVisible) {
        eventsQuery = query(
          eventsQuery,
          startAfter(lastVisible)
        );
      }

      const snapshot = await getDocs(eventsQuery);
      const lastVisibleDoc = snapshot.docs[snapshot.docs.length - 1];
      
      const events = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      return {
        events,
        lastVisible: lastVisibleDoc
      };
    } catch (error) {
      console.error('Error fetching events:', error);
      throw error;
    }
  },

  // Get a single event by ID
  getEventById: async (eventId) => {
    try {
      const eventDoc = await getDoc(doc(db, 'events', eventId));
      if (eventDoc.exists()) {
        return {
          id: eventDoc.id,
          ...eventDoc.data()
        };
      }
      return null;
    } catch (error) {
      console.error('Error fetching event:', error);
      throw error;
    }
  },

  // Get event categories
  getCategories: async () => {
    try {
      const categoriesRef = collection(db, 'eventCategories');
      const snapshot = await getDocs(categoriesRef);
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.error('Error fetching categories:', error);
      throw error;
    }
  }
}; 