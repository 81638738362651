import { storage, auth } from '../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/webp'];
const MAX_RETRIES = 3;
const TIMEOUT_MS = 120000; // 2 minutes
const STALL_TIMEOUT = 30000; // 30 seconds

export const uploadImage = async (file, folder = 'event-images', retryCount = 0) => {
  try {
    // Check authentication first
    if (!auth.currentUser) {
      throw new Error('User must be authenticated to upload files');
    }

    console.log('Starting upload process for file:', file.name);
    console.log('File size:', file.size, 'bytes');
    console.log('File type:', file.type);
    console.log('Retry count:', retryCount);
    console.log('User authenticated:', !!auth.currentUser);

    // Validate file size
    if (file.size > MAX_FILE_SIZE) {
      const error = new Error(`File size should not exceed ${MAX_FILE_SIZE / (1024 * 1024)}MB`);
      console.error('File size validation failed:', error);
      throw error;
    }

    // Validate file type
    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      const error = new Error('File type not supported. Please upload a JPEG, PNG, or WebP image.');
      console.error('File type validation failed:', error);
      throw error;
    }

    // Create a unique filename
    const timestamp = Date.now();
    const fileName = `${timestamp}_${file.name.replace(/[^a-zA-Z0-9.]/g, '_')}`;
    console.log('Generated filename:', fileName);

    // Create storage reference
    const storageRef = ref(storage, `${folder}/${fileName}`);
    console.log('Storage reference created for path:', `${folder}/${fileName}`);

    // Create upload task with metadata
    const metadata = {
      contentType: file.type,
      customMetadata: {
        originalName: file.name,
        uploadTimestamp: timestamp.toString(),
        uid: auth.currentUser.uid
      }
    };

    console.log('Starting upload with metadata:', metadata);
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    // Return a promise that resolves with the download URL
    return new Promise((resolve, reject) => {
      let lastProgress = 0;
      let lastProgressTime = Date.now();
      let uploadStartTime = Date.now();

      // Set up main timeout
      const timeoutId = setTimeout(() => {
        console.log('Upload timeout reached');
        uploadTask.cancel();
        if (retryCount < MAX_RETRIES) {
          console.log(`Retrying upload (attempt ${retryCount + 1} of ${MAX_RETRIES})`);
          resolve(uploadImage(file, folder, retryCount + 1));
        } else {
          reject(new Error('Upload timed out after multiple retries'));
        }
      }, TIMEOUT_MS);

      // Set up progress check interval
      const progressCheckId = setInterval(() => {
        const now = Date.now();
        if (lastProgress === 0 && (now - lastProgressTime) > STALL_TIMEOUT) {
          console.log('Upload appears to be stalled');
          clearInterval(progressCheckId);
          clearTimeout(timeoutId);
          uploadTask.cancel();
          if (retryCount < MAX_RETRIES) {
            console.log(`Retrying upload (attempt ${retryCount + 1} of ${MAX_RETRIES})`);
            resolve(uploadImage(file, folder, retryCount + 1));
          } else {
            reject(new Error('Upload stalled after multiple retries'));
          }
        }
      }, 5000); // Check every 5 seconds

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Progress function
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          const state = snapshot.state;
          
          if (progress > lastProgress) {
            lastProgress = progress;
            lastProgressTime = Date.now();
          }

          console.log(`Upload progress: ${progress.toFixed(2)}% (${state})`);
          console.log(`Bytes transferred: ${snapshot.bytesTransferred}/${snapshot.totalBytes}`);
          console.log(`Time elapsed: ${((Date.now() - uploadStartTime) / 1000).toFixed(1)}s`);
        },
        (error) => {
          // Clear all timers
          clearTimeout(timeoutId);
          clearInterval(progressCheckId);
          
          // Error function
          console.error('Upload error occurred:', error);
          console.error('Error code:', error.code);
          console.error('Error message:', error.message);
          
          // Handle retry for specific errors
          if ((error.code === 'storage/retry-limit-exceeded' || 
               error.code === 'storage/canceled') && 
              retryCount < MAX_RETRIES) {
            console.log(`Retrying upload (attempt ${retryCount + 1} of ${MAX_RETRIES})`);
            resolve(uploadImage(file, folder, retryCount + 1));
            return;
          }
          
          // Provide more specific error messages
          let errorMessage;
          switch (error.code) {
            case 'storage/unauthorized':
              errorMessage = 'User is not authorized to upload files. Please log in.';
              break;
            case 'storage/retry-limit-exceeded':
              errorMessage = 'Upload failed due to poor network connection. Please try again.';
              break;
            case 'storage/canceled':
              errorMessage = 'Upload was canceled due to inactivity. Please try again.';
              break;
            case 'storage/invalid-checksum':
              errorMessage = 'File upload failed due to data corruption. Please try again.';
              break;
            case 'storage/server-file-wrong-size':
              errorMessage = 'Upload failed due to file size mismatch. Please try again.';
              break;
            default:
              errorMessage = error.message;
          }
          reject(new Error(errorMessage));
        },
        async () => {
          // Clear all timers
          clearTimeout(timeoutId);
          clearInterval(progressCheckId);
          
          // Success function
          try {
            console.log('Upload completed successfully');
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            console.log('Download URL obtained:', downloadURL);
            resolve(downloadURL);
          } catch (error) {
            console.error('Error getting download URL:', error);
            reject(error);
          }
        }
      );
    });
  } catch (error) {
    console.error('Upload preparation failed:', error);
    if (retryCount < MAX_RETRIES) {
      console.log(`Retrying upload (attempt ${retryCount + 1} of ${MAX_RETRIES})`);
      return uploadImage(file, folder, retryCount + 1);
    }
    throw error;
  }
}; 