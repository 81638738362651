import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import logo from '../assets/images/unieros-logo.png';
import './Navigation.css';

const Navigation = () => {
  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <Navbar expand="lg" className="navbar-unieros" style={{ backgroundColor: '#ffcc33' }}>
      <Container>
        <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
          <img
            src={logo}
            alt="Unieros Logo"
            height="40"
            className="d-inline-block align-top me-2"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/" className="nav-link-unieros">Home</Nav.Link>
            <Nav.Link as={Link} to="/about" className="nav-link-unieros">About</Nav.Link>
            <Nav.Link as={Link} to="/events" className="nav-link-unieros">Events</Nav.Link>
            <Nav.Link as={Link} to="/news" className="nav-link-unieros">News</Nav.Link>
            <Nav.Link as={Link} to="/partnerships" className="nav-link-unieros">Partnerships</Nav.Link>
            <Nav.Link as={Link} to="/tech" className="nav-link-unieros">Tech</Nav.Link>
            <Nav.Link as={Link} to="/contact" className="nav-link-unieros">Contact</Nav.Link>
          </Nav>
          <Nav>
            {auth.currentUser ? (
              <>
                <Nav.Link as={Link} to="/admin" className="nav-link-unieros">Admin Dashboard</Nav.Link>
                <Nav.Link onClick={handleLogout} className="nav-link-unieros">Logout</Nav.Link>
              </>
            ) : (
              <Nav.Link as={Link} to="/login" className="nav-link-unieros">Admin Login</Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation; 