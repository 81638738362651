import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

export const checkIsAdmin = async (uid) => {
  if (!uid) {
    console.log('No UID provided for admin check');
    return false;
  }
  
  try {
    console.log('Checking admin status for UID:', uid);
    const adminDoc = await getDoc(doc(db, 'admins', uid));
    const isAdmin = adminDoc.exists();
    console.log('Is admin?', isAdmin);
    return isAdmin;
  } catch (error) {
    console.error('Error checking admin status:', error);
    return false;
  }
}; 