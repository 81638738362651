import React, { useState, useEffect } from 'react';
import { auth, db, storage } from '../../firebase';
import { 
  collection, 
  addDoc, 
  updateDoc, 
  deleteDoc,
  doc, 
  getDocs,
  query,
  orderBy,
  serverTimestamp 
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { onAuthStateChanged } from 'firebase/auth';
import { Button, Form, Table, Modal, Spinner, Alert, Image, Toast } from 'react-bootstrap';
import { checkIsAdmin } from '../../utils/adminUtils';
import imageCompression from 'browser-image-compression';
import { uploadImage as uploadImageUtil } from '../../utils/uploadUtils';

// Image compression options
const compressionOptions = {
  maxSizeMB: 1,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
  fileType: 'image/jpeg'
};

const AdminDashboard = () => {
  const [events, setEvents] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // Form state
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    date: '',
    category: '',
    location: '',
    image: '',
    link: ''
  });

  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    // Check authentication and admin status
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setIsAuthenticated(!!user);
      if (user) {
        const adminStatus = await checkIsAdmin(user.uid);
        setIsAdmin(adminStatus);
        if (adminStatus) {
          fetchEvents();
          fetchCategories();
        }
      } else {
        setIsAdmin(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const fetchEvents = async () => {
    try {
      const eventsQuery = query(collection(db, 'events'), orderBy('date', 'desc'));
      const snapshot = await getDocs(eventsQuery);
      const eventsList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setEvents(eventsList);
    } catch (err) {
      setError('Error fetching events: ' + err.message);
    }
  };

  const fetchCategories = async () => {
    try {
      const snapshot = await getDocs(collection(db, 'eventCategories'));
      const categoriesList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCategories(categoriesList);
    } catch (err) {
      setError('Error fetching categories: ' + err.message);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        // Show loading state
        setUploadProgress(1);
        
        // Compress the image
        const compressedFile = await imageCompression(file, compressionOptions);
        
        // Update progress
        setUploadProgress(50);
        
        // Create preview URL
        const previewUrl = URL.createObjectURL(compressedFile);
        setImagePreview(previewUrl);
        
        // Store the compressed file
        setImageFile(compressedFile);
        
        // Reset progress
        setUploadProgress(0);
      } catch (error) {
        console.error('Error compressing image:', error);
        setError('Error processing image: ' + error.message);
        setUploadProgress(0);
      }
    }
  };

  const uploadImage = async (file) => {
    try {
      console.log('Starting image upload...'); // Debug log
      const downloadURL = await uploadImageUtil(file, 'event-images');
      console.log('Image upload completed, URL:', downloadURL); // Debug log
      return downloadURL;
    } catch (error) {
      console.error('Upload error details:', error);
      throw error; // Let the calling function handle the error
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setSubmitting(true);
    
    try {
      console.log('Starting event submission...'); // Debug log

      let imageUrl = formData.image;
      
      if (imageFile) {
        console.log('Uploading image...'); // Debug log
        imageUrl = await uploadImage(imageFile);
      }

      const eventData = {
        ...formData,
        image: imageUrl,
        date: new Date(formData.date).toISOString(),
        updatedAt: serverTimestamp(),
        createdAt: currentEvent ? currentEvent.createdAt : serverTimestamp()
      };

      console.log('Event data to save:', eventData); // Debug log

      if (currentEvent) {
        await updateDoc(doc(db, 'events', currentEvent.id), eventData);
        setSuccess('Event updated successfully!');
      } else {
        const docRef = await addDoc(collection(db, 'events'), eventData);
        console.log('Event added with ID:', docRef.id); // Debug log
        setSuccess('Event created successfully!');
      }

      setShowModal(false);
      setCurrentEvent(null);
      setFormData({
        title: '',
        description: '',
        date: '',
        category: '',
        location: '',
        image: '',
        link: ''
      });
      setImageFile(null);
      setImagePreview('');
      await fetchEvents(); // Refresh the events list
    } catch (err) {
      console.error('Error saving event:', err); // Debug log
      setError('Error saving event: ' + err.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleEdit = (event) => {
    setCurrentEvent(event);
    setFormData({
      title: event.title,
      description: event.description,
      date: new Date(event.date).toISOString().split('T')[0],
      category: event.category,
      location: event.location,
      image: event.image || '',
      link: event.link || ''
    });
    setShowModal(true);
  };

  const handleDelete = async (eventId) => {
    if (window.confirm('Are you sure you want to delete this event?')) {
      try {
        await deleteDoc(doc(db, 'events', eventId));
        fetchEvents();
      } catch (err) {
        setError('Error deleting event: ' + err.message);
      }
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="container mt-5">
        <Alert variant="warning">
          Please sign in to access the admin dashboard.
        </Alert>
      </div>
    );
  }

  if (!isAdmin) {
    return (
      <div className="container mt-5">
        <Alert variant="danger">
          You do not have permission to access this area. Only administrators can access the dashboard.
        </Alert>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="container mt-5 text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <h2>Event Management</h2>
      
      {success && (
        <Alert variant="success" dismissible onClose={() => setSuccess('')}>
          {success}
        </Alert>
      )}
      
      {error && (
        <Alert variant="danger" dismissible onClose={() => setError('')}>
          {error}
        </Alert>
      )}

      <Button 
        variant="primary" 
        className="mb-3"
        onClick={() => {
          setCurrentEvent(null);
          setFormData({
            title: '',
            description: '',
            date: '',
            category: '',
            location: '',
            image: '',
            link: ''
          });
          setShowModal(true);
        }}
      >
        Add New Event
      </Button>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Title</th>
            <th>Date</th>
            <th>Category</th>
            <th>Location</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {events.map(event => (
            <tr key={event.id}>
              <td>{event.title}</td>
              <td>{new Date(event.date).toLocaleDateString()}</td>
              <td>{event.category}</td>
              <td>{event.location}</td>
              <td>
                <Button 
                  variant="outline-primary" 
                  size="sm" 
                  className="me-2"
                  onClick={() => handleEdit(event)}
                >
                  Edit
                </Button>
                <Button 
                  variant="outline-danger" 
                  size="sm"
                  onClick={() => handleDelete(event.id)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={() => !submitting && setShowModal(false)} size="lg">
        <Modal.Header closeButton={!submitting}>
          <Modal.Title>
            {currentEvent ? 'Edit Event' : 'Add New Event'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Date</Form.Label>
              <Form.Control
                type="date"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Category</Form.Label>
              <Form.Select
                name="category"
                value={formData.category}
                onChange={handleInputChange}
                required
              >
                <option value="">Select a category</option>
                {categories.map(category => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Location</Form.Label>
              <Form.Control
                type="text"
                name="location"
                value={formData.location}
                onChange={handleInputChange}
                required
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Event Image</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
              {uploadProgress > 0 && (
                <div className="mt-2">
                  <div className="progress">
                    <div 
                      className="progress-bar" 
                      role="progressbar" 
                      style={{ width: `${uploadProgress}%` }}
                      aria-valuenow={uploadProgress} 
                      aria-valuemin="0" 
                      aria-valuemax="100"
                    >
                      {uploadProgress < 100 
                        ? (uploadProgress === 1 ? 'Compressing...' : `${uploadProgress}%`)
                        : 'Upload Complete'}
                    </div>
                  </div>
                </div>
              )}
              {(imagePreview || formData.image) && (
                <div className="mt-3">
                  <Image 
                    src={imagePreview || formData.image} 
                    alt="Event preview" 
                    style={{ maxWidth: '200px' }} 
                    thumbnail
                  />
                  {imageFile && (
                    <div className="mt-2 text-muted">
                      <small>
                        Original size: {(imageFile.size / (1024 * 1024)).toFixed(2)}MB
                      </small>
                    </div>
                  )}
                </div>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Event Link (Optional)</Form.Label>
              <Form.Control
                type="url"
                name="link"
                value={formData.link}
                onChange={handleInputChange}
                placeholder="https://example.com/event"
              />
            </Form.Group>

            <Button 
              variant="primary" 
              type="submit"
              disabled={submitting}
            >
              {submitting ? (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                  {currentEvent ? 'Updating...' : 'Creating...'}
                </>
              ) : (
                currentEvent ? 'Update Event' : 'Create Event'
              )}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AdminDashboard; 