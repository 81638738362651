import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Spinner, Alert } from 'react-bootstrap';
import { motion } from 'framer-motion';
import { eventService } from '../services/eventService';

// Helper function to format dates
const formatEventDate = (dateString) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  }).format(date);
};

const EventDetails = () => {
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [category, setCategory] = useState(null);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        setLoading(true);
        const eventData = await eventService.getEventById(eventId);
        if (!eventData) {
          throw new Error('Event not found');
        }
        setEvent(eventData);
        
        // Fetch category details
        const categories = await eventService.getCategories();
        const eventCategory = categories.find(cat => cat.id === eventData.category);
        setCategory(eventCategory);
      } catch (err) {
        console.error('Error fetching event details:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchEventDetails();
  }, [eventId]);

  if (loading) {
    return (
      <div className="text-center py-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return (
      <Container className="py-5">
        <Alert variant="danger">
          {error}
        </Alert>
        <Link to="/events/archive" className="btn btn-primary">
          Back to Events
        </Link>
      </Container>
    );
  }

  if (!event) {
    return (
      <Container className="py-5">
        <Alert variant="warning">
          Event not found
        </Alert>
        <Link to="/events/archive" className="btn btn-primary">
          Back to Events
        </Link>
      </Container>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Container className="py-5">
        <Row>
          <Col lg={8} className="mx-auto">
            <Link to="/events/archive" className="btn btn-outline-primary mb-4">
              <i className="bi bi-arrow-left me-2"></i>
              Back to Events
            </Link>

            <Card className="border-0 shadow-sm">
              {event.image && (
                <div className="event-image-container" style={{ height: '400px', overflow: 'hidden', position: 'relative' }}>
                  <Card.Img
                    variant="top"
                    src={event.image}
                    alt={event.title}
                    className="img-fluid w-100 h-100"
                    style={{
                      objectFit: 'contain',
                      backgroundColor: '#f8f9fa'
                    }}
                  />
                </div>
              )}
              
              <Card.Body className="p-4">
                <div className="mb-4">
                  {category && (
                    <span 
                      className="badge mb-2"
                      style={{
                        backgroundColor: category.color || '#ce2013',
                        color: 'white'
                      }}
                    >
                      <i className={`bi ${category.icon} me-1`}></i>
                      {category.name}
                    </span>
                  )}
                  <h1 className="display-5 mb-3">{event.title}</h1>
                  
                  <div className="text-muted mb-4">
                    <p className="mb-2">
                      <i className="bi bi-calendar-event me-2"></i>
                      {formatEventDate(event.date)}
                    </p>
                    <p className="mb-0">
                      <i className="bi bi-geo-alt me-2"></i>
                      {event.location}
                    </p>
                  </div>
                </div>

                <div className="event-description mb-4">
                  <h5 className="mb-3">About this Event</h5>
                  <p className="lead">{event.description}</p>
                </div>

                {event.link && (
                  <Button
                    href={event.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="primary"
                    size="lg"
                    className="w-100"
                    style={{
                      backgroundColor: category?.color || '#ce2013',
                      borderColor: category?.color || '#ce2013'
                    }}
                  >
                    Register for Event
                    <i className="bi bi-arrow-right ms-2"></i>
                  </Button>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
};

export default EventDetails; 