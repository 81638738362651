import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Container, Row, Col, Button, Card, Spinner } from 'react-bootstrap';
import { eventService } from '../services/eventService';
import './EventsArchive.css';
import { Link } from 'react-router-dom';

// Helper function to format dates
const formatEventDate = (dateString) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  }).format(date);
};

const CategoryButton = ({ category, isActive, onClick }) => (
  <Button
    variant={isActive ? 'primary' : 'outline-primary'}
    onClick={onClick}
    className="category-button rounded-pill px-4 py-2 me-2 mb-2"
    style={{
      '--category-color': category.color || '#ce2013',
      borderColor: 'var(--category-color)',
      backgroundColor: isActive ? 'var(--category-color)' : 'transparent',
      color: isActive ? 'white' : 'var(--category-color)'
    }}
  >
    <i className={`bi ${category.icon} me-2`}></i>
    {category.name}
  </Button>
);

const EventsArchive = () => {
  const [activeCategory, setActiveCategory] = useState('all');
  const [events, setEvents] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  // Fetch categories on component mount
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const fetchedCategories = await eventService.getCategories();
        setCategories([
          { id: 'all', name: 'All Events' },
          ...fetchedCategories
        ]);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchCategories();
  }, []);

  // Fetch events when category changes
  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      try {
        const { events: fetchedEvents, lastVisible: lastDoc } = await eventService.getEvents(activeCategory);
        setEvents(fetchedEvents);
        setLastVisible(lastDoc);
        setHasMore(fetchedEvents.length === 6); // 6 is our page size
      } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchEvents();
  }, [activeCategory]);

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
    setLastVisible(null);
  };

  const loadMore = async () => {
    if (loadingMore || !hasMore) return;
    
    setLoadingMore(true);
    try {
      const { events: moreEvents, lastVisible: lastDoc } = await eventService.getEvents(activeCategory, lastVisible);
      setEvents(prevEvents => [...prevEvents, ...moreEvents]);
      setLastVisible(lastDoc);
      setHasMore(moreEvents.length === 6);
    } catch (error) {
      console.error('Error loading more events:', error);
    } finally {
      setLoadingMore(false);
    }
  };

  if (loading) {
    return (
      <div className="text-center py-5">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <section className="events-archive py-5">
      <Container>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          <h2 className="text-center mb-3">Events Archive</h2>
          <p className="text-center text-muted mb-5">Explore our diverse collection of Unieros multicultural events and experiences</p>
          
          {/* Category Filter */}
          <div className="category-filters mb-5">
            <Row className="g-2 justify-content-center mb-4">
              {categories.map(category => (
                <Col key={category.id} xs="auto">
                  <CategoryButton
                    category={category}
                    isActive={activeCategory === category.id}
                    onClick={() => handleCategoryChange(category.id)}
                  />
                </Col>
              ))}
            </Row>
            {/* Category Description */}
            {activeCategory !== 'all' && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                className="text-center mb-4"
              >
                <p className="text-muted">
                  {categories.find(cat => cat.id === activeCategory)?.description}
                </p>
              </motion.div>
            )}
          </div>

          {/* Events Grid */}
          <Row className="g-4">
            {events.map((event, index) => (
              <Col key={event.id} lg={4} md={6}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <Card className="event-card h-100 border-0 shadow-sm">
                    <div className="event-image-container">
                      <Card.Img 
                        variant="top" 
                        src={event.image} 
                        alt={event.title}
                        className="event-image"
                      />
                    </div>
                    <Card.Body>
                      <div className="mb-2">
                        <span 
                          className="badge me-2"
                          style={{
                            backgroundColor: categories.find(cat => cat.id === event.category)?.color || '#ce2013',
                            color: 'white'
                          }}
                        >
                          <i className={`bi ${categories.find(cat => cat.id === event.category)?.icon} me-1`}></i>
                          {categories.find(cat => cat.id === event.category)?.name || event.category}
                        </span>
                        <small className="text-muted d-block mt-2">
                          <i className="bi bi-calendar-event me-2"></i>
                          {formatEventDate(event.date)}
                        </small>
                      </div>
                      <Card.Title>{event.title}</Card.Title>
                      <Card.Text>{event.description}</Card.Text>
                      <div className="mt-2">
                        <small className="text-muted">
                          <i className="bi bi-geo-alt me-1"></i>
                          {event.location}
                        </small>
                      </div>
                    </Card.Body>
                    <Card.Footer className="bg-white border-0">
                      <Button 
                        as={Link}
                        to={`/events/${event.id}`}
                        variant="outline-primary" 
                        className="w-100"
                        style={{
                          '--category-color': categories.find(cat => cat.id === event.category)?.color || '#ce2013',
                          borderColor: 'var(--category-color)',
                          color: 'var(--category-color)'
                        }}
                      >
                        View Details
                      </Button>
                    </Card.Footer>
                  </Card>
                </motion.div>
              </Col>
            ))}
          </Row>

          {/* Load More Button */}
          {hasMore && (
            <motion.div 
              className="text-center mt-5"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              <Button 
                variant="primary" 
                onClick={loadMore}
                className="px-5"
                disabled={loadingMore}
              >
                {loadingMore ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                    Loading...
                  </>
                ) : (
                  'Load More Events'
                )}
              </Button>
            </motion.div>
          )}
        </motion.div>
      </Container>
    </section>
  );
};

export default EventsArchive; 