// firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore, serverTimestamp } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration


const firebaseConfig = {
  apiKey: "AIzaSyAUfFfbP6pzqjmIsAGrPJX_Qnn9zf-th08",
  authDomain: "unieros-react.firebaseapp.com",
  projectId: "unieros-react",
  storageBucket: "unieros-react.firebasestorage.app",
  messagingSenderId: "1024289595776",
  appId: "1:1024289595776:web:cf7c1961accafac203a165",
  measurementId: "G-E6ZXBM626C"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { app, db, auth, storage, serverTimestamp };