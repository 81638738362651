import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import { Modal } from 'react-bootstrap';
import NewsletterSignup from './NewsletterSignUp';
import Slider from 'react-slick';
import FlagBanner from './FlagBanner';
import Testimonials from './Testimonials';
import PastEvents from '../pages/PastEvents';
import SocialMediaLinks from './SocialMedia';
import DanceParty from '../assets/images/slider/unieros_dance_party.jpg';
import Paris from '../assets/images/slider/paris.jpeg';
import Kingston from '../assets/images/slider/kingston.jpg';
import Bahamas from '../assets/images/slider/bahamas.png';
import Carnival from '../assets/images/slider/carnival_kingston23.jpeg';
import ArtBasel from '../assets/images/slider/art_basel_miami23.jpeg';
import AfroAmFlag from '../assets/images/AfroAm.png';
import GuhHaad from '../assets/images/mens-t-shirt-regular-white-front.png';
import CraftJourney from '../assets/images/womens_craft_jamaica_journey_teeshirt.png';
import colors from '../theme';

import Community from '../pages/Community';
import '../custom.css';
import './Home.css';

function Home() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

//Create a carousel of multiple images the user can scroll through
const carouselImages = [
  {
    src: `${DanceParty}`,
    alt: 'Unieros multicultural dance event',
    caption: 'Unieros Event',
    category: 'Multicultural Events',
    videoUrl: 'https://www.youtube.com/embed/YsxVHJUaBuA',
    videoTitle: 'Unieros Multicultural Event',
  },
 
  {
    src: `${Paris}`,
    alt: 'Paris',
    caption: 'The Moulin Rouge, Paris',
    category: 'Tours & Travel',
    videoUrl: 'https://www.youtube.com/embed/Aqy-cZw6qmk?si=xi-7hNeP30aAOd3',
    videoTitle: 'Paris, France with Christine',
  },

  {
    src: `${Kingston}`,
    alt: 'Kingston',
    caption: 'Bob Marley Day, Kingston Jamaica',
    category: 'Festivals',
    videoUrl: 'https://www.youtube.com/embed/PTt5cXeP42A?si=jshmgy36EswKeCzo',
  },
  {
    src: `${Bahamas}`,
    alt: 'Bahamas',
    caption: 'Goombay Festival Little Bahamas, Miami',
    category: 'Festivals',
    videoUrl: 'https://www.youtube.com/embed/LpCEdyo4iHs?si=WXb0x1wbdMVT7ZIl',
  },
  {
    src: `${Carnival}`,
    alt: 'Jamaica Carnival',
    caption: 'Jamaica Carnival, Kingston',
    category: 'Ethnic Community Tours',
    videoTitle: 'Jamaica Carnival 2023',
    videoUrl: 'https://www.youtube.com/embed/PTt5cXeP42A?si=tCFeuULQkijxdz9i'
  },
  {
    src: `${ArtBasel}`,
    alt: 'Art Basel Miami 2023',
    caption: 'Art Basel Miami with Argentinian Artist Alfredo Sagatori',
    category: 'Arts & Culture',
    videoTitle: 'Art Basel Miami 2023',
    videoUrl: 'https://www.youtube.com/embed/ARMKaSG9550?si=WcWfGagyQRnVrMEC'

  },
];
const carouselStyle = {
  height: 'auto',
  maxHeight: '600px',
  marginBottom: '2rem',
  overflow: 'hidden'
};

const [show, setShow] = useState(false);
const [videoUrl, setVideoUrl] = useState('');
const [videoTitle, setVideoTitle] = useState('');

const handleClose = () => setShow(false);
const handleShow = (url, title) => {
  setVideoUrl(url);
  setVideoTitle(title);
  setShow(true);
};

useEffect(() => {
  document.title = 'Unieros - One Love, Many Cultures';
}, []);

  return (
    <>
      <Helmet>
        <title>Unieros - One Love, Many Cultures | Multicultural Events & Travel Experiences</title>
        <meta name="description" content="Discover diverse cultural experiences, immersive travel tours, and multicultural events with Unieros. Join our global community celebrating unity in diversity." />
        <meta name="keywords" content="multicultural events, cultural tours, diversity, travel experiences, global community" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://unieros.com" />
      </Helmet>

      <div className="homepage">
        <section className="hero-section">
          <div className="hero-content">
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center"
            >
              <h1 className="text-white">
                Welcome to <span className="text-primary">Unieros</span>
              </h1>
              <p className="lead text-white">One Love, Many Cultures</p>
              <div className="hero-buttons">
                <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                  <Link to="/events" className="btn btn-primary">
                    Explore Events
                  </Link>
                </motion.div>
                <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                  <Link to="/tours" className="btn btn-outline-light">
                    Book Travel
                  </Link>
                </motion.div>
                <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                  <Link to="https://tech.unieros.com" className="btn btn-primary">
                    Digital Services
                  </Link>
                </motion.div>
              </div>
            </motion.div>
          </div>
        </section>

        <section className="featured-section py-5">
          <div className="container">
            <div className="row g-4">
              <motion.div 
                className="col-md-4"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
              >
                <div className="feature-card p-4 bg-light rounded-lg text-center">
                  <i className="bi bi-calendar-event" aria-hidden="true"></i>
                  <h3>Cultural Events</h3>
                  <p>Join our vibrant community events celebrating diversity</p>
                </div>
              </motion.div>
              <motion.div 
                className="col-md-4"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.2 }}
              >
                <div className="feature-card p-4 bg-light rounded-lg text-center">
                  <i className="bi bi-globe-americas" aria-hidden="true"></i>
                  <h3>Global Tours</h3>
                  <p>Experience authentic cultural journeys worldwide</p>
                </div>
              </motion.div>
              <motion.div 
                className="col-md-4"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.4 }}
              >
                <div className="feature-card p-4 bg-light rounded-lg text-center">
                  <i className="bi bi-bag-heart" aria-hidden="true"></i>
                  <h3>Cultural Products</h3>
                  <p>Shop authentic merchandise and cultural items</p>
                </div>
              </motion.div>
            </div>
          </div>
        </section>

        <div className="row">
          <div className="row mb-3 p-3">
            <FlagBanner />
          </div>
        </div>
        <div className="container-fluid px-4">
        <div className="row mb-5">
        <div className="col-12 home-explore text-center py-5">
        <h2 className="display-4 mb-4"> Ready for your next adventure? </h2>
          <p className="lead mx-auto" style={{ maxWidth: '800px' }}>
          Our curated events and immersive travel experiences are designed to celebrate diversity and unite explorers. Elevate your business with our cutting-edge digital services, tailored with a global edge for growth in a connected world.
       
          </p>
          
        </div>
      </div>
      { /* Upcoming event */ }
          <section>
          <div className="container">  
           <Community />

          <div className="row" >
   
            <div style={{ backgroundColor: colors.warmGold, color: colors.neutralGray }} className="mb-4">
            <p className="lead">Unieros Travel Vlog: Kingston to Ocho Rios, Jamaica</p>
            
            <p>Curious about Kingston the capital of Jamaica. Check out our Kingston Travel Tour.</p>
            <p><Link to="/tours" className="btn btn-unieros"> Book Tour </Link></p>

            </div>
          </div>

          <div className="row p-3">
          <div className="product-section">
            <div className="container">
              <div className="slider-container">
                <h2 className="display-6 text-center mb-4">Shop Our Collection</h2>
                <Slider {...settings}>
                  <div className="product-card">
                    <img src={CraftJourney} alt="Unieros 'Craft Your Journey' T-Shirt" className="img-fluid" />
                    <p className="fw-bold">Unieros T-Shirt 'Craft Your Journey' Jamaica Tour. Cotton T-shirt promoting Unieros 3-Day Kingston Tour. Sizes available for men and women.</p>
                    <div className="text-center">
                      <a href="https://unieros.threadless.com/designs/craft-your-journey" className="btn btn-unieros" role="button">
                        Shop Now
                      </a>
                    </div>
                  </div>
                  <div className="product-card">
                    <img src={AfroAmFlag} alt="Afro Americano flag" className="img-fluid" />
                    <p className="fw-bold">Celebrate African American heritage and lineage with this exclusive flag designed by Tribal Enterprises Africa</p>
                    <div className="text-center">
                      <a href="https://buy.stripe.com/14k17gaUGexm4x2bJ3" className="btn btn-unieros" role="button">
                        Shop Now
                      </a>
                    </div>
                  </div>
                  <div className="product-card">
                    <img src={GuhHaad} alt="Unieros 'Guh Haad and Done' T-Shirt" className="img-fluid" />
                    <p className="fw-bold">Unieros T-Shirt 'Guh Haad and Done'. Cotton Jamaican Patois T-shirt. Sizes available for children, men and women.</p>
                    <div className="text-center">
                      <a href="https://unieros.threadless.com/designs/guh-haad-and-done-jamaica-patois-tshirt" className="btn btn-unieros" role="button">
                        Shop Now
                      </a>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
          {/* Social Media Links */}
          <SocialMediaLinks />
          </div>
          
           {/* Newsletter Signup */ }
            
           <div className="newsletter-signup">
          <NewsletterSignup />
        </div>  
      </div>

          </section>
      
             { /* Past Unieros Experiences */ }
        <div className="container-fluid">
      <div className="row video-highlights mt-5 g-4">
      <div className="col-12 text-center mb-4">
        <h2> Unieros Video Highlights</h2>
        <p>Join us on a journey of culture and innovation where every connection broadens your horizon. </p>
        </div>
        {carouselImages.map((image, index) => (
          <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
            <div className="card h-100 border-0 shadow-sm hover-lift transition-300" onClick={() => handleShow(image.videoUrl)}>
            <div className="card-img-wrapper overflow-hidden">
              <img
                src={image.src}
                alt={image.alt}
                className="card-img-top transition-300"
                
              />
              </div>
              <div className="card-body">
              <span className="badge bg-secondary mb-2">{image.category}</span>
                <h5 className="card-title">{image.caption}</h5>
              </div>
            </div>
          </div>
        ))}
        <Modal show={show} onHide={handleClose} className="brand-modal">
          <Modal.Header closeButton>
            <Modal.Title>{videoTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe
              width="100%"
              height="315"
              src={videoUrl}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-secondary" onClick={handleClose}>
              Close
            </button>
          </Modal.Footer>
          </Modal>
      </div>
        </div>
    
        {/* Testimonials */}
        <section className="testimonials py-5">
          <div className="container">
            <Testimonials />
          </div>
        </section>

        {/* Past Events */}
        <section className="past-events py-5">
          <div className="container">
            <PastEvents />
          </div>
        </section>

        {/* Latest Blog Posts */}
        {/* <section className="latest-blog-posts py-5">
          <div className="container">
            <LatestBlogPosts />
          </div>
        </section> */}
        </div>
      </div>
    </>
  );
}

export default Home;
