import React, { useState } from 'react';
import { Card, Form, Button, Alert, Spinner } from 'react-bootstrap';
import { uploadImage } from '../utils/uploadUtils';
import { db, auth } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import internationalTravel from '../assets/images/international-travel.jpg';

import "react-datepicker/dist/react-datepicker.css";

function TourBooking() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess(false);

    try {
      if (!auth.currentUser) {
        throw new Error('You must be logged in to create a tour.');
      }

      let imageUrl = '';
      if (image) {
        try {
          imageUrl = await uploadImage(image);
          console.log('Image uploaded successfully:', imageUrl);
        } catch (uploadError) {
          console.error('Image upload error:', uploadError);
          throw new Error(`Image upload failed: ${uploadError.message}`);
        }
      }

      const tourData = {
        title,
        description,
        imageUrl,
        createdAt: new Date(),
        createdBy: auth.currentUser.uid,
      };

      await addDoc(collection(db, 'tours'), tourData);
      setSuccess(true);
      setTitle('');
      setDescription('');
      setImage(null);
      
    } catch (err) {
      console.error('Error saving tour:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-6 mb-4">
          <Card>
            <Card.Body>
              <h2 className="mb-4">Create a New Tour</h2>
              {error && <Alert variant="danger">{error}</Alert>}
              {success && <Alert variant="success">Tour created successfully!</Alert>}
              
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Image</Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                  <Form.Text className="text-muted">
                    Maximum file size: 5MB. Supported formats: JPEG, PNG, WebP
                  </Form.Text>
                </Form.Group>

                <Button
                  type="submit"
                  variant="primary"
                  disabled={loading}
                  className="w-100"
                >
                  {loading ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                      />
                      Creating Tour...
                    </>
                  ) : (
                    'Create Tour'
                  )}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </div>

        <div className="col-md-6">
          <Card>
            <Card.Body>
              <h3>Book a Tour Info Session</h3>
              <Card.Img variant="top" src={internationalTravel} />
              <h5 className="display-5">Want to learn more about this tour?</h5>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSdbYbFwJvMUeBOLtkia-ReOZXXDaQlp1o5chTLdF122SmnB-g/viewform"
                className="btn btn-unieros"
              >
                Book Tour Info Session
              </a>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default TourBooking;