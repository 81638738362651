import React from 'react';
import { Card, Button, Container, Row, Col, Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
// import firebaseIntances from "../firebaseConfig";
import Tours from './Tours';
import './Events.css';
import unierosGroupChicago from '../assets/images/unieros_group2012.jpg';
import jamaicaEvent from '../assets/images/Jamaica61_event.jpg';
import kingstonTour from '../assets/images/slider/kingston.jpg';


// const { db } = firebaseIntances;
function Events() {
  const location = useLocation();
  //Connect to firebase
  // useEffect(() => {
  //   const db = db.firestore();
  //   db.collection('events')
  //     .orderBy('date', 'asc')
  //     .get()
  //     .then((querySnapshot) => {
  // //       const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  // //       setEvents(data);
  // //     })
  // //     .catch((error) => {
  // //       console.log('Error getting events: ', error);
  // //     });
  // // }, []);

  // // const handleSearchChange = (event) => {
  // //   setSearchTerm(event.target.value);
  // // };

  // const filteredEvents = events.filter((event) => {
  //   return (
  //     event.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     event.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     event.title.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  // });
  document.title = 'Unieros Multicultural Events';

  return (
    <Container className="py-5">
      <Row className="py-lg-5">
        <Col lg={8} className="mx-auto text-center">
          <h1 className="display-1 page-title">Events</h1>
          <p className="lead text-body-secondary mb-4">
            Explore and connect with different cultures through Unieros events.
            From food tours to music festivals, you'll love the immersive and welcoming atmosphere.
          </p>
          
          {/* Events Navigation */}
          <Nav className="justify-content-center mb-5">
            <Nav.Item>
              <Nav.Link 
                as={Link} 
                to="/events"
                className={`nav-link-unieros px-4 ${location.pathname === '/events' ? 'active' : ''}`}
              >
                Latest Events
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link 
                as={Link} 
                to="/events/archive"
                className={`nav-link-unieros px-4 ${location.pathname === '/events/archive' ? 'active' : ''}`}
              >
                Events Archive
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>

      <Row className="g-4">
        <Col xs={12}>
          <h2 className="mb-4">Featured Events</h2>
        </Col>
        
        <Col sm={12} md={6} lg={4}>
          <Card className="h-100 shadow-sm">
            <Card.Img variant="top" src={jamaicaEvent} alt="Jamaica Event" className="img-fluid" />
            <Card.Body>
              <Card.Title>Latest Event: Tings A Gwaan, Savannah, Georgia</Card.Title>
              <Card.Text>Experience the vibrant culture and rhythms of Jamaica in the heart of Savannah.</Card.Text>
            </Card.Body>
            <Card.Footer className="bg-white border-0 pb-3">
              <Link to={`/global/event-review-tings-a-gwaan-a-celebration-of-jamaican-caribbean-culture`} className="btn btn-unieros w-100">
                View Event Details
              </Link>
            </Card.Footer>
          </Card>
        </Col>

        <Col sm={12} md={6} lg={4}>
          <Card className="h-100 shadow-sm">
            <Card.Img variant="top" src={kingstonTour} alt="Unieros Tour Kingston Jamaica" className="img-fluid" />
            <Card.Body>
              <Card.Title>Kingston, Jamaica Tour</Card.Title>
              <Card.Text>Join us for an immersive cultural experience in the heart of Jamaica.</Card.Text>
            </Card.Body>
            <Card.Footer className="bg-white border-0 pb-3">
              <Link to="/tours" className="btn btn-unieros w-100">Book Now</Link>
            </Card.Footer>
          </Card>
        </Col>

        <Col sm={12} md={6} lg={4}>
          <Card className="h-100 shadow-sm bg-light">
            <Card.Body className="d-flex flex-column justify-content-center text-center p-4">
              <i className="bi bi-calendar-event mb-3" style={{ fontSize: '2rem', color: '#ce2013' }}></i>
              <Card.Title className="mb-3">Explore Past Events</Card.Title>
              <Card.Text>
                Discover our rich history of cultural events, celebrations, and community gatherings.
              </Card.Text>
              <Link to="/events/archive" className="btn btn-unieros mt-auto">
                Browse Events Archive
                <i className="bi bi-arrow-right ms-2"></i>
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col xs={12} className="text-center">
          <Link to="/events/archive" className="btn btn-outline-primary btn-lg">
            View All Events
            <i className="bi bi-arrow-right ms-2"></i>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default Events;